import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { PermissaoProva } from 'src/app/models/permissao-prova';
import { PlanoLoja } from 'src/app/models/plano-loja';
import { Plano } from 'src/app/models/plano';
import { Opcao } from 'src/app/models/opcao';

@Injectable({
  providedIn: 'root',
})
export class PlanoService {
  constructor(private http: HttpClient) {}

  getMesesDisponiveis(id: number) {
    return this.http.get<any[]>(`${environment.baseApiUrl}/planos/${id}/meses`);
  }

  getPlanos(curso: number) {
    return this.http.get<PlanoLoja>(`${environment.baseApiUrl}/planos?curso=${curso}`);
  }

  getPermissaoProvas() {
    return this.http.get<PermissaoProva>(`${environment.baseApiUrl}/planos/permissao-provas`);
  }

  getPlanoCarrinho(id: number) {
    return this.http.get<Plano>(`${environment.baseApiUrl}/planos/${id}/carrinho`);
  }

  getModulosPodcast(id: number) {
    return this.http.get<Opcao[]>(`${environment.baseApiUrl}/planos/${id}/modulos-podcast`);
  }
}
